import React, { memo } from "react";
import * as Styles from "./arrow.styles";

export const ArrowIcon = memo((props) => {
  return (
    <Styles.StyledSVG viewBox="0 0 100 100" {...props}>
      <g transform="matrix(1.05319,-1.05319,1.00805,1.00805,-1382.98,1052.07)">
        <path d="M1190.13,223.444C1190.13,219.871 1187.35,216.97 1183.93,216.97L1146.75,216.97C1143.33,216.97 1140.55,219.871 1140.55,223.444C1140.55,227.018 1143.33,229.919 1146.75,229.919L1183.93,229.919C1187.35,229.919 1190.13,227.018 1190.13,223.444Z" />
      </g>
      <g transform="matrix(-1.05319,-1.05319,1.00805,-1.00805,1032.5,1502.56)">
        <path d="M1190.13,223.444C1190.13,219.871 1187.35,216.97 1183.93,216.97L1146.75,216.97C1143.33,216.97 1140.55,219.871 1140.55,223.444C1140.55,227.018 1143.33,229.919 1146.75,229.919L1183.93,229.919C1187.35,229.919 1190.13,227.018 1190.13,223.444Z" />
      </g>
    </Styles.StyledSVG>
  );
});
