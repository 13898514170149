import React, { Fragment } from "react";
import * as yup from "yup";
import { CheckboxGroup } from "./components/form-elements/checkbox-group";
import { Label } from "./components/form-elements/label";
import { NumberInputGroup } from "./components/form-elements/number-input-group";
import {
  RadioButtonGroup,
  RadioButtonWithTextInputGroup,
} from "./components/form-elements/radio-button-group";
import { Select } from "./components/form-elements/select";
import { TextInputGroup } from "./components/form-elements/text-input-group";
import { Spacer } from "./components/spacer";

export const getComponentForQuestion = (
  question,
  values,
  setValues,
  errors
) => {
  const { id, label, required, options, items, displayConditions, component } =
    question;

  const value = values[id];
  const changeField = (field, value) =>
    setValues((prevState) => ({ ...prevState, [field]: value }));
  const onChange = (newValue) => changeField(id, newValue);

  if (displayConditions) {
    const conditionResults = Object.keys(displayConditions).map((field) => {
      const value = values[field];
      const expectedValue = displayConditions[field];

      if (Array.isArray(value)) {
        return value.some((item) => item.startsWith(expectedValue));
      } else {
        return value.startsWith(expectedValue);
      }
    });

    if (conditionResults.some((result) => !result)) {
      return false;
    }
  }

  switch (component) {
    case "checkboxes":
      return (
        <CheckboxGroup
          key={id}
          id={id}
          label={label}
          required={required}
          options={options}
          value={value}
          onChange={onChange}
          error={errors[id]}
        />
      );

    case "radio":
      return (
        <RadioButtonGroup
          key={id}
          id={id}
          label={label}
          required={required}
          value={value}
          onChange={onChange}
          options={options}
          error={errors[id]}
        />
      );

    case "numeric-input":
      return (
        <NumberInputGroup
          key={id}
          id={id}
          label={label}
          required={required}
          value={value}
          onChange={onChange}
          error={errors[id]}
        />
      );

    case "text-input":
      return (
        <TextInputGroup
          key={id}
          id={id}
          label={label}
          required={required}
          value={value}
          onChange={onChange}
          error={errors[id]}
        />
      );

    case "slider":
      return (
        <Select
          key={id}
          id={id}
          label={label}
          required={required}
          options={options}
          value={value}
          onChange={onChange}
          error={errors[id]}
        />
      );

    case "radio-with-text-input":
      return (
        <RadioButtonWithTextInputGroup
          key={id}
          id={id}
          label={label}
          required={required}
          options={options}
          value={value}
          onChange={onChange}
          error={errors[id]}
        />
      );

    case "checkboxes-with-nested-input":
      return (
        <CheckboxGroup
          key={id}
          id={id}
          label={label}
          required={required}
          options={options}
          value={value}
          onChange={onChange}
          error={errors[id]}
        />
      );

    case "fieldset":
      return (
        <Fragment key={id}>
          <Label>
            {label} {required && "(required)"}
          </Label>
          <Spacer height={1} />
          {items.map((item) =>
            getComponentForQuestion(item, values, setValues, errors)
          )}
        </Fragment>
      );

    default:
      return null;
  }
};

export const getField = (inputValue) => {
  if (!inputValue || typeof inputValue !== "string") return "";

  const split = inputValue.split("[");

  return split[0];
};

export const getValue = (inputValue) => {
  if (!inputValue || typeof inputValue !== "string") return "";

  const split = inputValue.split("[");

  if (split.length > 1) {
    return split[1].replace("]", "");
  } else {
    return "";
  }
};

export const getValidationSchema = (questions) => {
  const shape = questions
    .filter((question) => question.required)
    .reduce((accumulator, currentQuestion) => {
      if (currentQuestion.component === "fieldset") {
        const fields = currentQuestion.items.reduce(
          (nestedAccumulator, currentItem) => {
            return {
              ...nestedAccumulator,
              [currentItem.id]: yup.string().required({
                [currentItem.id]: `${currentItem.label} is a required field`,
              }),
            };
          },
          {}
        );

        return { ...accumulator, ...fields };
      }

      return {
        ...accumulator,
        [currentQuestion.id]: currentQuestion.type
          ? yup.array().min(1, {
              [currentQuestion.id]: `${currentQuestion.label} is a required field`,
            })
          : yup.string().required({
              [currentQuestion.id]: `${currentQuestion.label} is a required field`,
            }),
      };
    }, {});

  return yup.object().shape(shape);
};

export const withSafeWindow = (func, defaultValue) => {
  if (typeof window !== "undefined") {
    return func(window);
  }
  return defaultValue;
};
