import React, { memo } from "react";
import * as Styles from "./checkbox.styles";

export const Checkbox = memo(({ name, label, value, onChange, checked }) => (
  <Styles.Label>
    <Styles.Button
      name={name}
      value={value}
      onChange={onChange}
      checked={checked}
    />
    {label}
  </Styles.Label>
));
