import css from "@styled-system/css";
import styled from "styled-components";

export const ErrorMessage = styled.span`
  ${css({ color: "status.error", fontSize: [0], marginTop: [1] })}
`;

export const HelperErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${css({
    color: "status.error",
    fontSize: [1],
    paddingX: [1],
  })}
`;
