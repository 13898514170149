import React, { memo } from "react";
import * as Styles from "./select.styles";
import { Label } from "../label";
import { Spacer } from "../../spacer";
import { ErrorMessage } from "../error";

export const Select = memo(
  ({ id, label, required, options, value, onChange, error }) => {
    return (
      <>
        {label && (
          <Label>
            {label} {required && "(required)"}
          </Label>
        )}
        <Spacer height={1} />
        <Styles.Wrapper>
          <Styles.StyledSelect
            id={id}
            value={value}
            onChange={(event) => onChange(event.target.value)}
          >
            <option value="">Please select...</option>
            {options.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Styles.StyledSelect>
          <Styles.Arrow variant="background.secondary" />
          {error && <ErrorMessage>{error}</ErrorMessage>}
        </Styles.Wrapper>
      </>
    );
  }
);
