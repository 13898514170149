import React, { forwardRef, memo } from "react";
import * as Styles from "./text-input.styles";

export const TextInput = memo(
  forwardRef(({ name, value, onChange, onFocus, onBlur }, ref) => {
    return (
      <Styles.Input
        ref={ref}
        name={name}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    );
  })
);
