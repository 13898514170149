import css from "@styled-system/css";
import styled from "styled-components";

export const Label = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  transition: background-color 500ms;

  ${css({
    borderRadius: [2],
    fontSize: [1],
    padding: [1],
    "&:hover": {
      backgroundColor: "background.tertiary",
    },
  })}
`;

export const Button = styled.input.attrs({
  type: "radio",
})`
  ${css({
    margin: [0],
    marginRight: [1],
  })}
`;
