import css from "@styled-system/css";
import styled from "styled-components";

export const Outer = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Inner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: ${(props) => props.theme.breakpoints[2]};
  width: 100%;

  ${css({ paddingX: [2, 2, 3] })}
`;
