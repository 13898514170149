import React, { createRef } from "react";
import * as Styles from "../checkbox-group.styles";
import { Checkbox } from "../../checkbox";
import { TextInput } from "../../text-input";

export const CheckboxWithNestedTextInput = ({
  name,
  value,
  label,
  selectedValue,
  nestedInputValue,
  onNestedInputChange,
  onToggleOn,
  onToggleOff,
}) => {
  const nestedTextInputRef = createRef();

  const onToggle = () => {
    if (selectedValue) {
      onToggleOff();
    } else {
      nestedTextInputRef.current.focus();
      onToggleOn(`${value}[${nestedInputValue}]`);
    }
  };

  const onCustomTextFocus = () => {
    if (!selectedValue) {
      onToggleOn(`${value}[${nestedInputValue}]`);
    }
  };

  return (
    <>
      <Checkbox
        label={label}
        name={name}
        value={value}
        checked={selectedValue}
        onChange={onToggle}
      />
      <Styles.TextInputWrapper>
        <TextInput
          name={name}
          value={nestedInputValue}
          onChange={(event) => onNestedInputChange(event.target.value)}
          onFocus={onCustomTextFocus}
          ref={nestedTextInputRef}
        />
      </Styles.TextInputWrapper>
    </>
  );
};
