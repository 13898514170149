import React, { useState } from "react";
import * as Styles from "./page-container.styles";
import { withSafeWindow } from "../../utils";

export const PageContainer = ({ children }) => {
  const [windowHeight, setWindowHeight] = useState(
    withSafeWindow((window) => window.innerHeight - 150)
  );

  withSafeWindow((window) => {
    window.addEventListener(
      "resize",
      function () {
        setWindowHeight(withSafeWindow((window) => window.innerHeight - 150));
      },
      true
    );
  });

  return (
    <Styles.Container windowHeight={windowHeight}>{children}</Styles.Container>
  );
};
