import React, { memo } from "react";

export const Logo = memo(() => {
  return (
    <svg
      viewBox="0 0 500 500"
      style={{
        fillRule: "evenodd",
        clipRule: "evenodd",
        strokeLinejoin: "round",
        strokeMiterlimit: "2",
      }}
    >
      <defs>
        <linearGradient
          id="gradBlue"
          x1="100%"
          y1="100%"
          x2="0%"
          y2="0%"
          gradientTransform="rotate(90)"
        >
          <stop
            offset="60%"
            style={{ stopColor: "#007ECC", stopOpacity: "1" }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "#007ECC", stopOpacity: "0" }}
          />
        </linearGradient>
        <linearGradient
          id="gradRed"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="100%"
          gradientTransform="rotate(0)"
        >
          <stop
            offset="40%"
            style={{ stopColor: "#CC0028", stopOpacity: "0" }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "#CC0028", stopOpacity: "1" }}
          />
        </linearGradient>
        <radialGradient
          id="gradWhite"
          cx="50%"
          cy="50%"
          r="50%"
          fx="50%"
          fy="50%"
        >
          <stop
            offset="65%"
            style={{ stopColor: "#FFFFFF", stopOpacity: "0" }}
          />
          <stop
            offset="100%"
            style={{ stopColor: "#FFFFFF", stopOpacity: "1" }}
          />
        </radialGradient>
      </defs>
      <g transform="matrix(0.822447,0,0,0.822447,-550.594,-234.729)">
        <circle cx="973.429" cy="589.374" r="303.971" fill="#6600CC" />
        <circle cx="973.429" cy="589.374" r="303.971" fill="url(#gradRed)" />
        <circle cx="973.429" cy="589.374" r="303.971" fill="url(#gradBlue)" />
        <circle
          cx="973.429"
          cy="589.374"
          r="303.971"
          fill="url(#gradWhite)"
          style={{ opacity: "0.2" }}
        />
      </g>
      <g transform="matrix(150,0,0,150,382.3,302.5)">
        <path
          d="M0.028,-0.109L0.075,-0.165C0.117,-0.126 0.157,-0.098 0.194,-0.081C0.231,-0.064 0.272,-0.056 0.317,-0.056C0.371,-0.056 0.416,-0.07 0.451,-0.097C0.486,-0.124 0.504,-0.157 0.504,-0.197C0.504,-0.232 0.492,-0.258 0.469,-0.277C0.445,-0.296 0.405,-0.31 0.348,-0.32L0.235,-0.339C0.171,-0.35 0.124,-0.37 0.095,-0.398C0.065,-0.425 0.05,-0.462 0.05,-0.509C0.05,-0.568 0.072,-0.616 0.117,-0.653C0.162,-0.69 0.219,-0.709 0.29,-0.709C0.342,-0.709 0.39,-0.702 0.435,-0.688C0.48,-0.673 0.524,-0.651 0.567,-0.62L0.527,-0.56C0.491,-0.587 0.453,-0.607 0.413,-0.622C0.372,-0.637 0.332,-0.644 0.291,-0.644C0.242,-0.644 0.202,-0.632 0.171,-0.608C0.14,-0.583 0.124,-0.553 0.124,-0.517C0.124,-0.486 0.134,-0.462 0.155,-0.445C0.176,-0.428 0.209,-0.415 0.256,-0.408L0.368,-0.389C0.442,-0.376 0.496,-0.354 0.53,-0.324C0.564,-0.294 0.581,-0.253 0.581,-0.201C0.581,-0.171 0.574,-0.143 0.561,-0.117C0.548,-0.091 0.529,-0.069 0.506,-0.05C0.482,-0.031 0.454,-0.017 0.421,-0.006C0.388,0.005 0.353,0.01 0.315,0.01C0.26,0.01 0.21,0 0.163,-0.02C0.116,-0.039 0.071,-0.069 0.028,-0.109Z"
          style={{ fill: "white", fillRule: "nonzero" }}
        />
      </g>
      <g transform="matrix(150,0,0,150,253.15,302.5)">
        <path
          d="M0.43,-0.05L0.161,-0.611L0.161,-0L0.089,-0L0.089,-0.7L0.196,-0.7L0.43,-0.202L0.664,-0.7L0.771,-0.7L0.771,-0L0.698,-0L0.698,-0.609L0.43,-0.05Z"
          style={{ fill: "white", fillRule: "nonzero" }}
        />
      </g>
      <g transform="matrix(150,0,0,150,143.5,302.5)">
        <path
          d="M0.088,-0L0.088,-0.7L0.165,-0.7L0.165,-0.387L0.567,-0.387L0.567,-0.7L0.644,-0.7L0.644,-0L0.567,-0L0.567,-0.318L0.165,-0.318L0.165,-0L0.088,-0Z"
          style={{ fill: "white", fillRule: "nonzero" }}
        />
      </g>
      <g transform="matrix(150,0,0,150,22,302.5)">
        <path
          d="M0.753,-0.35C0.753,-0.3 0.744,-0.253 0.726,-0.209C0.708,-0.164 0.683,-0.126 0.652,-0.094C0.621,-0.061 0.584,-0.036 0.542,-0.017C0.499,0.002 0.454,0.011 0.405,0.011C0.357,0.011 0.312,0.002 0.269,-0.017C0.226,-0.036 0.189,-0.061 0.158,-0.094C0.127,-0.126 0.102,-0.164 0.084,-0.209C0.066,-0.253 0.057,-0.3 0.057,-0.35C0.057,-0.4 0.066,-0.447 0.084,-0.492C0.102,-0.536 0.127,-0.574 0.158,-0.607C0.189,-0.639 0.226,-0.664 0.269,-0.683C0.312,-0.702 0.357,-0.711 0.405,-0.711C0.454,-0.711 0.499,-0.702 0.542,-0.683C0.584,-0.664 0.621,-0.639 0.652,-0.607C0.683,-0.574 0.708,-0.536 0.726,-0.492C0.744,-0.447 0.753,-0.4 0.753,-0.35ZM0.675,-0.35C0.675,-0.391 0.668,-0.429 0.654,-0.465C0.64,-0.5 0.621,-0.531 0.597,-0.557C0.573,-0.583 0.545,-0.604 0.512,-0.619C0.479,-0.634 0.443,-0.641 0.405,-0.641C0.367,-0.641 0.332,-0.634 0.299,-0.619C0.266,-0.604 0.237,-0.583 0.213,-0.557C0.189,-0.531 0.17,-0.5 0.156,-0.465C0.142,-0.429 0.135,-0.391 0.135,-0.35C0.135,-0.309 0.142,-0.271 0.156,-0.236C0.17,-0.2 0.189,-0.169 0.213,-0.143C0.237,-0.117 0.266,-0.097 0.299,-0.082C0.332,-0.067 0.367,-0.059 0.405,-0.059C0.443,-0.059 0.479,-0.067 0.512,-0.082C0.545,-0.097 0.573,-0.117 0.597,-0.143C0.621,-0.169 0.64,-0.2 0.654,-0.236C0.668,-0.271 0.675,-0.309 0.675,-0.35Z"
          style={{ fill: "white", fillRule: "nonzero" }}
        />
      </g>
    </svg>
  );
});
