import React from "react";
import * as Styles from "../checkbox-group.styles";
import { Checkbox } from "../../checkbox";
import { RadioButton } from "../../radio-button";

export const CheckboxWithNestedRadio = ({
  name,
  value,
  label,
  selectedValue,
  nestedInputValue,
  onNestedInputChange,
  onToggleOn,
  onToggleOff,
  nestedOptions,
}) => {
  const onToggle = () => {
    if (selectedValue) {
      onToggleOff();
    } else {
      onToggleOn(`${value}[${nestedInputValue}]`);
    }
  };

  return (
    <>
      <Checkbox
        label={label}
        name={name}
        value={value}
        checked={selectedValue}
        onChange={onToggle}
      />
      {nestedOptions.map((nestedOption) => (
        <Styles.NestedOptionWrapper
          key={`${name}-${value}-${nestedOption.value}`}
        >
          <RadioButton
            name={name}
            label={nestedOption.label}
            value={nestedOption.value}
            checked={nestedOption.value === nestedInputValue}
            onChange={() => onNestedInputChange(nestedOption.value)}
          />
        </Styles.NestedOptionWrapper>
      ))}
    </>
  );
};
