const breakpoints = ["768px", "1024px", "1280px"];

const blackTransparent = "rgba(0, 0, 0, 0.2)";
const blueLight = "#38B3FF";
const blue = "#007ECC";
const blueDark = "#094879";
const greyLightest = "#EEE";
const greyLight = "#DDD";
const grey = "#999";
const greyDark = "#666";
const navyLight = "#28283A";
const navy = "#131226";
const purple = "#6600CC";
const purpleDark = "#471483";
const red = "#CC0028";
const redDark = "#7A1431";
const white = "#FFF";

const borders = {
  dashed: `3px dashed ${greyLightest}`,
};

const colors = {
  foreground: {
    primary: navyLight,
    secondary: white,
    tertiary: greyDark,
  },
  background: {
    primary: white,
    secondary: navyLight,
    tertiary: greyLightest,
    header: navy,
  },
  border: {
    primary: greyLight,
  },
  button: {
    primary: {
      foreground: white,
      background: purple,
      hover: purpleDark,
    },
    secondary: {
      foreground: navyLight,
      background: greyLightest,
      hover: greyLight,
    },
    tertiary: {
      foreground: white,
      background: blue,
      hover: blueDark,
    },
    danger: {
      foreground: white,
      background: red,
      hover: redDark,
    },
    disabled: {
      foreground: greyLight,
      background: greyDark,
    },
    menu: {
      foreground: navyLight,
      background: white,
      hover: greyLightest,
    },
  },
  status: {
    active: purple,
    inactive: grey,
    error: red,
  },
};

const fontSizes = ["0.75rem", "1rem", "1.4rem", "1.6rem", "2rem", "2.5rem"];

const fontWeights = {
  light: `300`,
  regular: `400`,
  bold: `700`,
  extraBold: `800`,
};

const radii = [0, 5, 10, 20, 40];

const shadows = {
  focus: `inset 0 0 0 2px ${blueLight};`,
  glow: `0 0 40px ${blackTransparent}`,
  glowSmall: `0 0 10px ${blackTransparent}`,
};

const space = [0, 10, 20, 40, 80];

const fonts = {
  headings: "'Red Hat Text', sans-serif",
  body: "'Roboto', sans-serif",
};

export const theme = {
  borders,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  radii,
  shadows,
  space,
};
