import React, { memo } from "react";
import * as Styles from "./text-input-group.styles";
import { TextInput } from "../text-input";
import { Label } from "../label";
import { Spacer } from "../../spacer";
import { ErrorMessage } from "../error";

export const TextInputGroup = memo(
  ({ id, label, required, value, onChange, error }) => {
    return (
      <Styles.Wrapper>
        <Label>
          {label} {required && "(required)"}
        </Label>
        <Spacer height={1} />
        <TextInput
          name={id}
          value={value}
          onChange={(event) => onChange(event.target.value)}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Styles.Wrapper>
    );
  }
);
