import styled from "styled-components";
import css from "@styled-system/css";

export const Box = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  ${(props) =>
    css({
      backgroundColor: "background.primary",
      color: "foreground.primary",
      borderRadius: [3],
      boxShadow: "glow",
      padding: props.padding ?? [2, 3],
    })}
`;

export const BoxContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const EmptyBox = styled(Box)`
  && {
    align-items: center;
    box-shadow: none;
    border: ${(props) => props.theme.borders.dashed};
    display: flex;
    justify-content: center;

    ${css({
      color: "status.inactive",
      fontSize: [4],
      fontWeight: "bold",
    })}
  }
`;
