import styled from "styled-components";
import css from "@styled-system/css";

export const StyledSVG = styled.svg`
  clip-rule: evenodd;
  fill-rule: evenodd;
  height: 18px;
  stroke-linejoin: round;
  stroke-miterlimit: 2;
  transition: transform 500ms;
  width: 18px;

  ${({ orientation, variant }) =>
    css({
      fill: variant ? variant : "background.primary",
      transform:
        orientation === "top"
          ? "rotate(180deg)"
          : orientation === "left"
          ? "rotate(90deg)"
          : orientation === "right"
          ? "rotate(-90deg)"
          : "rotate(0deg)",
    })}
`;
