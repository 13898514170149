import css from "@styled-system/css";
import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
* {
  box-sizing: border-box;
}

body {
  margin: 0;

  ${css({
    color: "foreground.primary",
    fontFamily: "body",
  })}
}

a {
  text-decoration: none;
  color: inherit;
}

`;
