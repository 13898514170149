import css from "@styled-system/css";
import styled from "styled-components";

export const Label = styled.label`
  text-transform: uppercase;

  ${css({
    fontSize: [0],
    fontWeight: "bold",
  })}
`;
