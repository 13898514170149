import React, { memo } from "react";
import * as Styles from "./checkbox-group.styles";
import { Label } from "../label";
import { Spacer } from "../../spacer";
import { getField, getValue } from "../../../utils";
import { ErrorMessage } from "../error";
import { CheckboxWithNestedCheckboxes } from "./checkbox-with-nested-checkboxes";
import { CheckboxWithNestedRadio } from "./checkbox-with-nested-radio";
import { CheckboxWithNestedTextInput } from "./checkbox-with-nested-text-input";
import { PlainCheckbox } from "./plain-checkbox";

export const CheckboxGroup = memo(
  ({ id, label, required, options, value, onChange, error }) => {
    const items = options.map((option) => {
      const selectedValues = value;
      const selectedValueForOption =
        selectedValues &&
        selectedValues.length &&
        selectedValues.find((item) => item.startsWith(option.value));

      let selectedValue = selectedValueForOption || "";
      let nestedInputValue;

      if (
        option.nestedTextInput ||
        option.nestedRadio ||
        option.nestedCheckboxes
      ) {
        selectedValue = getField(selectedValue);
        nestedInputValue = selectedValueForOption
          ? getValue(selectedValueForOption)
          : "";
      }

      const toggleOn = (valueToAdd) =>
        onChange([...selectedValues, valueToAdd]);

      const toggleOff = () =>
        onChange(
          selectedValues.filter((item) => !item.startsWith(option.value))
        );

      const updateNestedTextInput = (nestedInputValue) => {
        onChange(
          selectedValues.map((item) => {
            if (item.startsWith(option.value)) {
              return `${option.value}[${nestedInputValue}]`;
            }
            return item;
          })
        );
      };

      const updateNestedRadioInput = (nestedRadioValue) => {
        if (!selectedValue) {
          toggleOn(`${option.value}[${nestedRadioValue}]`);
        } else {
          onChange(
            selectedValues.map((item) => {
              if (item.startsWith(option.value)) {
                return `${option.value}[${nestedRadioValue}]`;
              }
              return item;
            })
          );
        }
      };

      const updateNestedCheckboxesInput = (nestedCheckboxValue) => {
        if (!selectedValue) {
          toggleOn(`${option.value}[${nestedCheckboxValue}]`);
        } else {
          onChange(
            selectedValues.map((item) => {
              if (item.startsWith(option.value)) {
                const selectedItems = nestedInputValue.split(",");
                const selectedItemForValue = selectedItems.find(
                  (item) => item === nestedCheckboxValue
                );

                let updatedItems;
                if (selectedItemForValue) {
                  updatedItems = selectedItems.filter(
                    (item) => item !== nestedCheckboxValue
                  );
                } else {
                  updatedItems = selectedItems.concat([nestedCheckboxValue]);
                }
                return `${option.value}[${updatedItems.join(",")}]`;
              }
              return item;
            })
          );
        }
      };

      if (option.nestedTextInput) {
        return (
          <CheckboxWithNestedTextInput
            key={`${id}-${option.value}`}
            name={id}
            value={option.value}
            label={option.label}
            selectedValue={selectedValue}
            nestedInputValue={nestedInputValue}
            onNestedInputChange={updateNestedTextInput}
            onToggleOn={toggleOn}
            onToggleOff={toggleOff}
          />
        );
      }

      if (option.nestedRadio) {
        return (
          <CheckboxWithNestedRadio
            key={`${id}-${option.value}`}
            name={id}
            value={option.value}
            label={option.label}
            selectedValue={selectedValue}
            nestedInputValue={nestedInputValue}
            onNestedInputChange={updateNestedRadioInput}
            onToggleOn={toggleOn}
            onToggleOff={toggleOff}
            nestedOptions={option.nestedOptions}
          />
        );
      }

      if (option.nestedCheckboxes) {
        return (
          <CheckboxWithNestedCheckboxes
            key={`${id}-${option.value}`}
            name={id}
            value={option.value}
            label={option.label}
            selectedValue={selectedValue}
            nestedInputValue={nestedInputValue}
            onNestedInputChange={updateNestedCheckboxesInput}
            onToggleOn={toggleOn}
            onToggleOff={toggleOff}
            nestedOptions={option.nestedOptions}
          />
        );
      }

      return (
        <PlainCheckbox
          key={`${id}-${option.value}`}
          name={id}
          value={option.value}
          label={option.label}
          selectedValue={selectedValue}
          onToggleOn={toggleOn}
          onToggleOff={toggleOff}
        />
      );
    });

    return (
      <Styles.Wrapper>
        <Label>
          {label} {required && "(required)"}
        </Label>
        <Spacer height={1} />
        {items}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Styles.Wrapper>
    );
  }
);
