import css from "@styled-system/css";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${({ windowHeight }) =>
    css({
      marginY: [3],
      minHeight: windowHeight ? windowHeight : "100vh",
    })}
`;
