import css from "@styled-system/css";
import styled from "styled-components";

export const Input = styled.input.attrs({
  type: "number",
})`
  border: 1px solid;
  transition: box-shadow 500ms;

  ${css({
    borderColor: "border.primary",
    borderRadius: [1],
    fontFamily: "body",
    fontSize: [1],
    padding: [1],
    "&:focus": {
      outline: "none",
      boxShadow: "focus",
    },
  })}
`;
