import React from "react";
import styled from "styled-components";
import { ThemeProvider } from "styled-components";
import { theme } from "../theme";
import { GlobalStyle } from "./global-style";
import { Header } from "./header";
import { Seo } from "./seo";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <Seo />
      <Wrapper>
        <GlobalStyle />
        <Header />
        {children}
      </Wrapper>
    </ThemeProvider>
  );
};
