import styled from "styled-components";
import css from "@styled-system/css";

export const StyledButton = styled("button").withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !["loading"].includes(prop) && defaultValidatorFn(prop),
})`
  align-items: center;
  animation: gradient 2s ease infinite;
  background-size: 200% 200%;
  border: 0;
  display: flex;
  justify-content: center;

  &:disabled {
    pointer-events: none;
  }

  &:focus {
    outline: none;
    transition: box-shadow 500ms ease;
  }

  &:hover {
    cursor: pointer;
  }

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 100%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  ${({ variant, small, loading }) =>
    css({
      backgroundColor: variant
        ? `button.${variant}.background`
        : "button.primary.background",
      backgroundImage: loading
        ? `linear-gradient(-45deg, #999, #0A0B0D)`
        : "none",
      borderRadius: small ? [1] : [2],
      color: loading
        ? "button.disabled.foreground"
        : variant
        ? `button.${variant}.foreground`
        : "button.primary.foreground",
      fontFamily: "headings",
      fontWeight: "bold",
      fontSize: small ? [0] : [1],
      px: small ? [1] : [2],
      py: small ? "5px" : [1],
      transition: "background-color 500ms, color 500ms",
      "&:disabled": {
        backgroundColor: "button.disabled.background",
        color: "button.disabled.foreground",
      },
      "&:focus": {
        boxShadow: "focus",
      },
      "&:hover": {
        backgroundColor: variant
          ? `button.${variant}.hover`
          : "button.primary.hover",
      },
    })}
`;
