import React, { memo } from "react";
import * as Styles from "./button-container.styles";

export const ButtonContainer = memo(({ children }) => {
  return <Styles.Container>{children}</Styles.Container>;
});

export const ButtonsColumn = memo(({ children }) => {
  return <Styles.Column>{children}</Styles.Column>;
});
