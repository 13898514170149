import css from "@styled-system/css";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
`;

export const LogoWrapper = styled.div`
  height: 50px;
  width: 50px;
`;

export const Wrapper = styled.div`
  ${css({
    backgroundColor: "background.header",
    paddingY: [1],
  })}
`;
