import { memo } from "react";
import styled from "styled-components";
import css from "@styled-system/css";

const headerStyles = {
  fontFamily: "headings",
  fontWeight: "bold",
  margin: 0,
  padding: 0,
};

export const H1 = memo(
  styled.h1`
    ${css({ ...headerStyles, fontSize: [4] })}
  `
);
export const H2 = memo(
  styled.h2`
    ${css({ ...headerStyles, fontSize: [3] })}
  `
);
export const H3 = memo(
  styled.h3`
    ${css({ ...headerStyles, fontSize: [2] })}
  `
);

export const Subtitle = memo(styled.p`
  ${css({ ...headerStyles, color: "foreground.tertiary", fontSize: [1] })}
`);

export const Text = memo(styled.p`
  ${css({ fontFamily: "body", fontSize: [1] })}
`);

export const ErrorMessage = memo(styled.span`
  ${css({ color: "status.error", fontSize: [0] })}
`);
