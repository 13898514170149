import React, { createRef, Fragment, memo } from "react";
import * as Styles from "./radio-button-group.styles";
import { Label } from "../label";
import { RadioButton } from "../radio-button";
import { Spacer } from "../../spacer";
import { TextInput } from "../text-input";
import { getField, getValue } from "../../../utils";
import { ErrorMessage } from "../error";

export const RadioButtonGroup = memo(
  ({ id, label, required, options, value, onChange, error }) => {
    const items = options.map((option) => (
      <Fragment key={`${id}-${option.value}`}>
        <RadioButton
          label={option.label}
          name={id}
          value={option.value}
          checked={option.value === value}
          onChange={(event) => onChange(event.target.value)}
        />
      </Fragment>
    ));

    return (
      <Styles.Wrapper>
        <Label>
          {label} {required && "(required)"}
        </Label>
        <Spacer height={1} />
        {items}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Styles.Wrapper>
    );
  }
);

export const RadioButtonWithTextInputGroup = memo(
  ({ id, label, required, options, value, onChange, error }) => {
    const items = options.map((option) => {
      let checkedRadioButtonValue = value;
      let radioButtonValue = option.value;
      let customTextValue;

      const nestedTextInputRef = createRef();

      if (option.nestedTextInput) {
        checkedRadioButtonValue = getField(value);
        radioButtonValue = getField(option.value);
        customTextValue =
          radioButtonValue === checkedRadioButtonValue ? getValue(value) : "";
      }

      const onCustomTextChange = (event) => {
        const newValue = event.target.value;
        onChange(`${radioButtonValue}[${newValue}]`);
      };

      const onRadioButtonChange = (event) => {
        if (option.nestedTextInput) {
          onChange(`${event.target.value}[${customTextValue}]`);
          nestedTextInputRef.current.focus();
        } else {
          onChange(event.target.value);
        }
      };

      return (
        <Fragment key={`${id}-${option.value}`}>
          <RadioButton
            label={option.label}
            name={id}
            value={radioButtonValue}
            checked={radioButtonValue === checkedRadioButtonValue}
            onChange={onRadioButtonChange}
          />
          {option.nestedTextInput && (
            <Styles.TextInputWrapper>
              <TextInput
                name={id}
                value={customTextValue}
                onChange={onCustomTextChange}
                ref={nestedTextInputRef}
              />
            </Styles.TextInputWrapper>
          )}
        </Fragment>
      );
    });

    return (
      <Styles.Wrapper>
        <Label>
          {label} {required && "(required)"}
        </Label>
        <Spacer height={1} />
        {items}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </Styles.Wrapper>
    );
  }
);
