import css from "@styled-system/css";
import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${css({
    marginBottom: [2],
    "&:last-child": {
      marginBottom: [0],
    },
  })}
`;
