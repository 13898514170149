import css from "@styled-system/css";
import styled from "styled-components";
import { ArrowIcon } from "../../icons/arrow";

export const Arrow = styled(ArrowIcon)`
  margin-top: -9px;
  pointer-events: none;
  position: absolute;
  right: 11px;
  top: 50%;
`;

export const StyledSelect = styled.select`
  appearance: none;
  border: 1px solid;
  font: inherit;
  transition: box-shadow 500ms;
  width: 100%;

  ${css({
    borderColor: "border.primary",
    borderRadius: [1],
    color: "foreground.tertiary",
    p: [1],
  })}

  &:focus {
    outline: none;

    ${css({
      boxShadow: "focus",
    })}
  }
`;

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  ${css({
    marginBottom: [2],
    "&:last-child": {
      marginBottom: [0],
    },
  })}
`;
