import React from "react";
import { Checkbox } from "../../checkbox";

export const PlainCheckbox = ({
  id,
  value,
  label,
  selectedValue,
  onToggleOn,
  onToggleOff,
}) => {
  const onToggle = () => {
    if (selectedValue) {
      onToggleOff();
    } else {
      onToggleOn(value);
    }
  };

  return (
    <>
      <Checkbox
        label={label}
        name={id}
        value={value}
        checked={selectedValue === value}
        onChange={onToggle}
      />
    </>
  );
};
