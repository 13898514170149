import styled from "styled-components";
import css from "@styled-system/css";

export const Container = styled.div`
  display: flex;
  justify-content: flex-end;

  ${css({
    flexDirection: ["column", "column", "row"],
  })}
`;

export const Column = styled(Container)`
  flex-direction: row;
`;
