import css from "@styled-system/css";
import styled from "styled-components";

export const Wrapper = styled.div`
  ${css({
    marginBottom: [2],
    "&:last-child": {
      marginBottom: [0],
    },
  })}
`;

export const TextInputWrapper = styled.div`
  ${css({
    padding: [1],
  })}

  input {
    width: 100%;
  }
`;
