import React, { memo } from "react";
import * as Styles from "./header.styles";
import { Logo } from "../logo";
import { MaxWidthContainer } from "../max-width-container";

export const Header = memo(() => (
  <Styles.Wrapper>
    <MaxWidthContainer>
      <Styles.Container>
        <Styles.LogoWrapper>
          <a href="/" title="One Health Management System">
            <Logo />
          </a>
        </Styles.LogoWrapper>
      </Styles.Container>
    </MaxWidthContainer>
  </Styles.Wrapper>
));
